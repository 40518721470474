<template>
  <div>
    <v-dialog v-model="show" max-width="550px">
      <v-card>
        <v-card-title class="headline">{{title}}</v-card-title>

        <v-card-text v-if="loading">
          Выполняется запрос
          <v-progress-linear
              indeterminate
              color="blue"
              class="mb-0"
          ></v-progress-linear>
        </v-card-text>

        <v-card-actions v-if="!loading">
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">Отмена</v-btn>
          <v-btn color="blue darken-1" text @click="confirm">Да, удалить</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'DeleteDialog',
  props: {
    'title': String,
    'notifyText': String,
    'api': Object,
  },
  data: () => ({
    show: false,
    loading: false,
    deletedId: -1,
  }),
  methods: {
    close () {
      this.show = false
      this.loading = false
      this.$nextTick(() => {
        this.deletedId = -1
      })
    },
    showDialog (itemId) {
      this.deletedId = itemId
      this.loading = false
      this.show = true
    },
    confirm () {
      this.loading = true

      this
          .api
          .deleteItem(this.deletedId)
          .then(() => {
            this.$notify({
              type: 'info',
              text: this.notifyText,
            })
            this.$emit('update')
          })
          .finally(() => {
            this.loading = false
            this.close()
          })
    },
  },
}
</script>